import '../styles/blog.scss';

import { Link } from 'gatsby';
import React, { useMemo, useState } from 'react';

import BlogPostItem from '../components/blog-post-item';
import Boilerplate from '../components/boilerplate';
import SectionHeading from '../components/common/section-heading';
import Image from '../components/image';
import { Box, Container, Section } from '../components/layout';
import { PAGE_SIZE } from '../config/blog';
import { usePageData } from '../data';
import { useBlogPostsPartialsData } from '../data/hooks/use-blog-post-partial-data';
import ArrowDownSvg from '../images/arrow-down.svg';
import ArrowRightSvg from '../images/arrow-right.svg';
import { Template } from '../types/templates';
import {
  contentfulJSONToReactComponents,
  formatBlogPostDate,
  resolveBlogPostPath,
} from '../utils';
import { classNameMap } from '../utils/tsx';

/* eslint-disable unused-imports/no-unused-vars-ts */
const BlogTemplate: Template = ({ pageContext: { id } }) => {
  const { data } = usePageData(id);
  const posts = useBlogPostsPartialsData();

  const [page, setPage] = useState(0);

  const maxPages = useMemo(() => Math.ceil(posts.length / PAGE_SIZE), [posts]);
  const visibleItems = useMemo(
    () => posts.slice(page * PAGE_SIZE, page * PAGE_SIZE + PAGE_SIZE),
    [posts, page],
  );

  const featured = page === 0 ? visibleItems[0] : undefined;
  const list = page === 0 ? visibleItems.slice(1) : visibleItems;

  return (
    <Boilerplate seoId={data.seo.id} pageId={id}>
      <Section className="blog__hero-section">
        <Container>
          <SectionHeading
            title="Blog"
            description="Uwielbiamy dzielić się naszymi myślami. Bądź na bieżąco z nami i z branżą beauty oraz trendami w mikropigmentacji."
          />
        </Container>
      </Section>

      {featured && (
        <Section
          className="blog__featured-section"
          hidden={featured === undefined}
        >
          <Container>
            <Box
              style={{
                sm: { borderRadius: '2rem', height: '20rem' },
                md: { borderRadius: '3rem', height: '30rem' },
                lg: { borderRadius: '3.5rem', height: '35rem' },
              }}
            >
              <Image id={featured.image.id} />
            </Box>
            <h2>{featured.title}</h2>
            <time>{formatBlogPostDate(featured.date)}</time>
            <p>{contentfulJSONToReactComponents(featured.excerpt.json)}</p>

            <Link to={resolveBlogPostPath(featured.date, featured.slug)}>
              <Box align="middle">
                {/* TODO reuse generic button component */}
                <span style={{ marginRight: '0.5rem' }}>Czytaj całość</span>
                <ArrowRightSvg />
              </Box>
            </Link>
          </Container>
        </Section>
      )}

      <Section className="blog__items-section">
        <Container>
          {list.map((item, index) => (
            <BlogPostItem item={item} key={index} />
          ))}
        </Container>
      </Section>

      <Section className="blog__pagination-section">
        <Container>
          <Box className="blog__prev-page-button" fullHeight>
            <div
              role="button"
              onClick={() => setPage(current => Math.max(0, current - 1))}
            >
              <ArrowDownSvg />
            </div>
          </Box>

          <div className="blog__pages-items">
            {Array.from({ length: maxPages }, (_, index) => (
              <div
                className={classNameMap({
                  'blog__page-item': true,
                  active: index === page,
                })}
                onClick={() => setPage(index)}
              >
                {index + 1}
              </div>
            ))}
          </div>

          <Box className="blog__next-page-button" fullHeight>
            <div
              role="button"
              onClick={() =>
                setPage(current => Math.min(maxPages - 1, current + 1))
              }
            >
              <ArrowDownSvg />
            </div>
          </Box>
        </Container>
      </Section>
    </Boilerplate>
  );
};

export default BlogTemplate;
