import '../styles/blog-post-item.scss';

import { Typography } from 'antd';
import { Link } from 'gatsby';
import React from 'react';

import Image from '../components/image';
import { Box, Col, Row } from '../components/layout';
import ArrowRightSvg from '../images/arrow-right.svg';
import { BlogPostPartial } from '../types/blog';
import { contentfulJSONToReactComponents } from '../utils';
import { formatBlogPostDate, resolveBlogPostPath } from '../utils/blog';

const BlogPostItem: React.FC<{ item: BlogPostPartial }> = ({ item }) => (
  <div className="blog-post-item">
    <Row gutters="1.5rem">
      <Col span={8}>
        <Image id={item.image.id}></Image>
      </Col>

      <Col span={16}>
        <Typography.Title level={4}>{item.title}</Typography.Title>
        <time>{formatBlogPostDate(item.date)}</time>
        <Typography.Paragraph ellipsis={{ rows: 2 }}>
          {contentfulJSONToReactComponents(item.excerpt.json)}
        </Typography.Paragraph>

        <Link to={resolveBlogPostPath(item.date, item.slug)}>
          <Box align="middle">
            {/* TODO reuse generic button component */}
            <span style={{ marginRight: '0.5rem' }}>Czytaj całość</span>
            <ArrowRightSvg />
          </Box>
        </Link>
      </Col>
    </Row>
  </div>
);

export default BlogPostItem;
